import { accountModule } from "@/store/account";
const permissionDirective = {
    created(el, binding) {
        const checkAll = binding.value.checkAll === undefined ? true : binding.value.checkAll;
        const userHasAllRequired = accountModule.hasPermission(binding.value.permissions, checkAll);
        if (!userHasAllRequired) {
            el.style.display = "none";
        }
    },
};
export default permissionDirective;
