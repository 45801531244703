var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { IdentityClient, UserClient } from "@/api/nswag.generated";
import store from "@/store";
import claimTypes from "@/constant/claims";
let AccountModule = class AccountModule extends VuexModule {
    _claims = [];
    _isLoggedIn = false;
    _userData = null;
    setClaims(value) {
        this._claims = value;
    }
    setUserData(value) {
        this._userData = value;
    }
    async fetchClaims() {
        const claims = await new IdentityClient().getClaims();
        this.setClaims(claims);
    }
    async fetchUser() {
        const userData = await new UserClient().getUser();
        if (userData && userData.user) {
            this.setUserData(userData.user);
        }
    }
    get isLoggedIn() {
        return this._isLoggedIn;
    }
    get getAvatar() {
        return this._userData?.avatar;
    }
    get getUserName() {
        return this._userData?.firstName;
    }
    get getUserOrganizationId() {
        return this._userData?.organizationId;
    }
    setIsLoggedIn(value) {
        this._isLoggedIn = value;
    }
    get per() {
        return this._claims;
    }
    get getUserData() {
        return this._userData;
    }
    get hasPermission() {
        return (value, checkAll = true) => {
            const userPermissions = this._claims.filter((x) => x.type == claimTypes.PERMISSION);
            if (value) {
                if (Array.isArray(value)) {
                    if (checkAll) {
                        return value.map((x) => userPermissions.find((p) => p.value == x) != undefined).every((x) => x);
                    }
                    return value.map((x) => userPermissions.find((p) => p.value == x) != undefined).findIndex((a) => a) !== -1;
                }
                else {
                    return userPermissions.some((x) => x.value == value);
                }
            }
            return false;
        };
    }
};
__decorate([
    Mutation
], AccountModule.prototype, "setClaims", null);
__decorate([
    Mutation
], AccountModule.prototype, "setUserData", null);
__decorate([
    Action
], AccountModule.prototype, "fetchClaims", null);
__decorate([
    Action
], AccountModule.prototype, "fetchUser", null);
__decorate([
    Mutation
], AccountModule.prototype, "setIsLoggedIn", null);
AccountModule = __decorate([
    Module
], AccountModule);
export const accountModule = new AccountModule({ store, name: "account" });
accountModule.$watch((m) => m.isLoggedIn, async (is) => {
    if (is) {
        await accountModule.fetchClaims();
        await accountModule.fetchUser();
    }
}, { deep: false, immediate: true });
