export class DrawerItemModel {
    constructor(route, icon, classStyle, name, children) {
        this.route = route;
        this.icon = icon;
        this.classStyle = classStyle;
        this.name = name;
        this.children = children;
    }
    route;
    icon;
    classStyle;
    name;
    children;
}
