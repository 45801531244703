export default {
    home: {
        dashboard: "Pulpit",
        bestPractices: "Najlepsze praktyki",
    },
    account: {
        login: "Zaloguj się",
        logout: "Wyloguj się",
        settings: "Ustawienia konta",
        userName: "Nazwa użytkownika",
        password: "Hasło",
        confirmPassword: "Potwierdź hasło",
        forgotPassword: "Nie pamiętam hasła",
        title: "Edytuj profil",
        email: "Email",
        send: "Wyślij",
        newPassword: "Nowe hasło",
        newPasswordSameAsOld: "Nowe hasło musi różnić się od aktualnego.",
        changePassword: "Zmień hasło",
        usernameAlreadyExists: "Nazwa użytkownika już istnieje",
        emailAlreadyExists: "Email już istnieje",
        report: {
            label: "Preferencje raportu",
            periodLabel: "Okres",
            dayLabel: "Dzień tygodnia",
            hourLabel: "Godzina",
            minuteLabel: "Minuta",
            reportStatus: "Wysyłanie raportów: ",
            day: {
                Sunday: "Niedziela",
                Monday: "Poniedziałek",
                Tuesday: "Wtorek",
                Wednesday: "Środa",
                Thursday: "Czwartek",
                Friday: "Piątek",
                Saturday: "Sobota",
            },
            period: {
                Weekly: "Co tydzień",
            },
        },
    },
    drawer: {
        myLocations: "Moje lokalizacje",
        myOrganization: "Moja organizacja",
        administration: "Administracja",
        users: "Użytkownicy",
    },
    other: {
        english: "Język angielski",
        dutch: "Niderlandy",
    },
    input: {
        selectLanguage: "Wybierz język",
        month: "Miesiąc",
        year: "Rok",
    },
    administration: {
        administration: "Administracja",
        teams: "Zespoły",
        users: "Użytkownicy",
        organizations: "Organizacje",
        devicedepartments: "Działy",
        locations: "Lokalizacje",
        devices: "Urządzenia",
        editorganization: "Edytuj organizację",
        organization: "Organizacja",
        organizationdetails: "Szczegóły organizacji",
        userlist: "Lista użytkowników",
        useredit: "Dane użytkownika",
        locationedit: "Edytuj lokalizację",
        locationslist: "Lista lokalizacji",
        emailConfirmed: "E-mail potwierdzony",
        teamslist: "Lista zespołów",
        devicedepartmentslist: "Lista wydziałów",
        teamedit: "Edytuj zespół",
        devicedepartmentsedit: "Edytuj dział",
        deviceedit: "Edytuj urządzenie",
        deviceslist: "Lista urządzeń",
        locationdeviceedit: "Edytuj urządzenie",
        permissions: "Uprawnienia użytkowników",
        deviceslogs: "Logi z urządzenia",
    },
    permissions: {
        role: "Rola",
        administrationRead: "Administracja:Czytaj",
        administrationUpdate: "Administracja:Aktualizacja",
        locationRead: "Lokalizacja:Czytaj",
        locationUpdate: "Lokalizacja:Aktualizacja",
    },
    shared: {
        logType: "Typ zdarzenia",
        availableLater: "Ta funkcjonalność będzie dodana w późniejszym czasie",
        passive: "Pasywny",
        search: "Szukaj na stronie",
        name: "Nazwa",
        firstName: "Imię",
        lastName: "Nazwisko",
        id: "Id",
        email: "Email",
        roles: "Role",
        description: "Opis",
        organizationName: "Nazwa organizacji",
        locationName: "Nazwa lokalizacji",
        hardwareId: "Sprzęt Id",
        isActive: "Jest aktywny",
        active: "Aktywny",
        team: "Zespół",
        allTeams: "Wszystkie zespoły",
        deviceDepartment: "Dział urządzeń",
        departmentName: "Nazwa działu",
        teamName: "Nazwa zespołu",
        deviceName: "Nazwa urządzenia",
        save: "Zapisz",
        inactive: "Nieaktywny",
        status: "Status",
        confirm: "Potwierdź",
        noResults: "Nie znaleziono żadnych wyników.",
        time: "Czas",
        value: "Alert",
        alertLog: "Dziennik alarmowy",
        requestSuccess: "Success",
        requestError: "Wystąpił błąd",
        hello: "Witaj,",
        messageSentIfExists: "Zostało wysłane łącze do resetowania hasła.",
        backToLogin: "Powrót do strony logowania",
        wrongNameOrPass: "Nieprawidłowa wiadomość e-mail lub hasło",
        incorrectData: "Nieprawidłowe dane",
        passwordResetSuccess: "Pomyślne zresetowanie hasła",
        lift: "Podnośnik",
        department: "Dział",
        noDataSelectedMonth: "W wybranym miesiącu nie dokonano żadnych wpisów.",
        language: "Język",
        day: "Dzień",
        log: "Log",
    },
    actions: {
        add: "Dodaj",
        cancel: "Anuluj",
        edit: "Edytuj",
    },
    location: {
        addNew: "Dodaj nową lokalizację",
        name: "Nazwa",
        description: "Opis",
    },
    user: {
        firstName: "Imię",
        lastName: "Nazwisko",
        email: "Email",
        phoneNumber: "Numer telefonu",
        organizationName: "Nazwa organizacji",
        userName: "Nazwa użytkownika",
        password: "Hasło",
        passwordRepeat: "Powtórz hasło",
        addNew: "Dodaj nowego użytkownika",
        edit: "Edytuj użytkownika",
    },
    team: {
        name: "Nazwa zespołu",
        description: "Opis",
        addNew: "Dodaj nowy zespół",
        selectedLocation: "Wybrana lokalizacja",
        active: "Aktywny",
        inactive: "Nieaktywny",
        status: "Status",
        teamLeader: "Kierownik zespołu",
    },
    deviceDepartment: {
        name: "Nazwa wydziału",
        description: "Opis",
        addNew: "Dodaj nowy dział",
        selectedLocation: "Wybrana lokalizacja",
        active: "Aktywny",
        inactive: "Nieaktywny",
        status: "Status",
    },
    organization: {
        addNew: "Dodaj nową organizację",
        name: " Nazwa organizacji",
        description: "Opis",
        active: "Aktywny",
        inactive: "Nieaktywny",
        organizationStatus: "Status organizacji",
        phoneNumber: "Numer telefonu",
    },
    device: {
        addNew: "Dodaj nowe urządzenie",
        macAddress: "Adres MAC",
        hardwareId: "Identyfikator sprzętu",
        deviceName: "Nazwa urządzenia",
        liftType: "Typ podnośnika",
        activeLocationName: "Aktywny w danej lokalizacji",
        activeOrganizationName: "Aktywność w organizacji",
        imei: "IMEI",
        locationName: "Lokalizacja",
        teamName: "Zespół",
        department: "Dział",
        mobilityClass: "Klasa mobilności",
        series: "Seria",
        modelName: "Nazwa modelu",
        imeiAlreadyExists: "IMEI już istnieje",
        firmwareVersion: "Wersja",
        firmwareCommit: "Commit",
    },
    physicalLoad: {
        secondaryTitle: "Pulpit obciążenia fizycznego",
    },
    healthyTransfer: {
        mainLabel: "Procent zdrowego transferu",
        transferLabel: "transferów było zdrowych",
        notOk: "Przykro mi to mówić, ale nie jesteś w stanie osiągnąć zdrowych transferów.",
        almostOk: "Spróbuj skupić się na tym, jak osiągnąć lepsze, zdrowe transfery",
        justOk: "Chodzi o to, aby być na dobrej drodze do osiągnięcia 100% zdrowych transferów.",
        keepUp: "Pracujcie dalej! Jesteś na dobrej drodze do osiągnięcia celu, jakim jest 100% zdrowych transferów!",
        excellent: "Dobra robota! Osiągnąłeś cel 100% zdrowych transferów!",
        transferPercentage: "Odsetek zdrowych transferów",
        calculateText: "Obliczamy, ile procent Twoich przelewów mieści się w zalecanych limitach zgodnie z przepisami arbo.",
        arbo: "Regulacje Arbo",
        arboLimits: "Ograniczniki Arbo do pchania i ciągnięcia",
        oneHand: "z jedną ręką",
        twoHand: "z dwiema rękami",
        noTransfers: "W wybranym okresie nie dokonano żadnych przesunięć.",
    },
    locationDevice: {
        isActive: "Aktywny",
        deviceName: "Nazwa urządzenia",
        location: "Lokalizacja",
        device: "Urządzenie",
        team: "Zespół",
        deviceDepartment: "Dział urządzeń",
        mobilityClass: "Klasa mobilności",
    },
    routes: {
        myLocations: "Moje lokalizacje",
    },
    months: {
        january: "Styczeń",
        february: "Luty",
        march: "Marzec",
        april: "Kwiecień",
        may: "Maj",
        june: "Czerwiec",
        july: "Lipiec",
        august: "Sierpień",
        september: "Wrzesień",
        october: "Październik",
        november: "Listopad",
        december: "Grudzień",
        januari: "Styczeń",
        februari: "Luty",
        maart: "Marzec",
        mei: "Maj",
        juni: "Czerwiec",
        juli: "Lipiec",
        augustus: "Sierpień",
        oktober: "Październik",
        styczeń: "Styczeń",
        luty: "Luty",
        marzec: "Marzec",
        kwiecień: "Kwiecień",
        maj: "Maj",
        czerwiec: "Czerwiec",
        lipiec: "Lipiec",
        sierpień: "Sierpień",
        wrzesień: "Wrzesień",
        październik: "Październik",
        listopad: "Listopad",
        grudzień: "Grudzień",
    },
    takeAction: {
        action: "Podejmij działanie",
        learn: "Dowiedz się więcej o zapobieganiu przeciążeniu fizycznemu",
        learnHref: "Więcej",
        checkOut: "Zapoznaj się z często zadawanymi pytaniami",
        checkOutHref: "Do FAQ",
        bookAnalysis: "Umów się na osobistą analizę z ekspertami w dziedzinie ergonomii",
        bookAnalysisHref: "Dowiedz się więcej",
        learnHowToImprove: "Chcesz się dowiedzieć, jak możesz się poprawić?",
        expertsToHelp: "Nasi eksperci służą pomocą. Skontaktuj się z nami, aby uzyskać więcej informacji na temat zapobiegania przeciążeniu fizycznemu.",
        scheduleMeeting: "Zaplanuj spotkanie",
    },
    contact: {
        helpDiscuss: "Służymy pomocą i chętnie porozmawiamy o Twoich danych!",
        howCanHelp: "Jak możemy pomóc?",
    },
    overloadCalendar: {
        calendar: "Kalendarz",
    },
    monthlyOverview: {
        monthlyOverview: "Przegląd miesięczny",
        nrPatientTransfer: "Liczba przeniesień pacjentów",
        nrForceAlerts15: "Liczba ostrzeżeń o sile > 15 kg",
        nrForceAlerts20: "Liczba ostrzeżeń o sile > 20 kg",
    },
    annualOverview: {
        annualOverview: "Roczny przegląd",
    },
    patientMobility: {
        mainLabel: "Klasy z zakresu mobilności pacjentów",
        valueName: "Klasy",
        progressName: "Średni odsetek zdrowych transferów",
    },
    departments: {
        mainLabel: "Działy",
        valueName: "Nazwa",
        progressName: "Średni odsetek zdrowych transferów",
    },
    liftType: {
        mainLabel: "Typ podnośnika",
        valueName: "Nazwa",
        progressName: "Średni odsetek zdrowych transferów",
    },
    thisMonth: "W tym miesiącu",
    thisYear: "W tym roku",
    userDetailsKey: {
        organizationName: "Nazwa organizacji",
        phoneNumber: "Numer telefonu",
        email: "Adres email",
        lastName: "Nazwisko",
        firstName: "Imię",
        userName: "Nazwa użytkownika",
        roleName: "Rola",
        locationName: "Nazwa lokalizacji",
        resendActivationEmail: "Ponownie wyślij aktywacyjną wiadomość e-mail",
    },
    rules: {
        required: "Pole jest wymagane",
        email: "Email jest nieprawidłowy",
        length8: "Hasło musi mieć długość co najmniej 8 znaków",
        passwordDiff: "Hasło i powtórzone hasło nie są zgodne",
        lengthTo30: "Pole jest zbyt długie: maks. 30",
        lengthTo50: "Pole jest zbyt długie: maks. 50",
        lengthTo100: "Pole jest zbyt długie: maks. 100",
        imei: "Imei powinien mieć 16 znaków.",
    },
    alertLog: {
        logType: "Typ zdarzenia",
        value: "Przeciążenia i transfery",
    },
};
