import AuthService from "@/services/authorization-service";
import RouteNames from "@/router/route-names";
import { createRouter, createWebHistory } from "vue-router";
import { accountModule } from "@/store/account";
const routes = [
    {
        path: "/",
        redirect: { name: RouteNames.dashboardView },
    },
    {
        path: "/dashboard/:locationId?/:alertLog?",
        name: RouteNames.dashboardView,
        props: {
            locationId: String,
            alertLog: String,
        },
        meta: {
            requiresAuth: true,
        },
        component: () => import("../views/PhysicalLoadDashboard.vue"),
    },
    {
        path: "/best-practices",
        name: RouteNames.bestPracticesView,
        component: () => import("../views/BestPractices.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: RouteNames.loginView,
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/confirmAccount",
        name: "ConfirmAccount",
        component: () => import("../views/confirm-account/ConfirmAccount.vue"),
    },
    {
        path: "/account",
        name: RouteNames.accountView,
        component: () => import("../views/Account.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/forgot-password",
        name: RouteNames.forgotPasswordView,
        component: () => import("../views/ForgotPassword.vue"),
    },
    {
        path: "/reset-password",
        name: RouteNames.resetPasswordView,
        component: () => import("../views/ResetPassword.vue"),
    },
    {
        path: "/administration/",
        redirect: { name: RouteNames.userListView },
        meta: {
            requiresAuth: true,
        },
        component: () => import("../views/administration/Administration.vue"),
        children: [
            {
                path: "users/",
                name: RouteNames.userView,
                redirect: { name: RouteNames.userListView },
                component: () => import("../views/administration/users/Users.vue"),
                children: [
                    {
                        path: "list",
                        name: RouteNames.userListView,
                        component: () => import("@/views/administration/users/list/UsersList.vue"),
                        meta: {
                            permissions: ["USERS:READ"],
                        },
                    },
                    {
                        path: "edit/:id",
                        name: RouteNames.userEditView,
                        props: {
                            id: String,
                        },
                        meta: {
                            permissions: ["USERS:READ", "USERS:MODIFY"],
                        },
                        component: () => import("@/views/administration/users/edit/UserEdit.vue"),
                    },
                ],
            },
            {
                path: "permissions",
                name: RouteNames.permissionsView,
                component: () => import("../views/administration/permissions/Permissions.vue"),
                meta: {
                    permissions: ["PERMISSIONS:READ"],
                },
            },
            {
                path: "organizations",
                name: RouteNames.organizationView,
                component: () => import("../views/administration/organizations/Organizations.vue"),
                meta: {
                    permissions: ["ORGANIZATIONS:READ"],
                },
            },
            {
                path: "organizations/details/:id",
                name: RouteNames.organizationDetailsView,
                props: {
                    id: Number,
                },
                component: () => import("../views/administration/organizations/details/OrganizationDetails.vue"),
                meta: {
                    permissions: ["ORGANIZATIONS:READ", "ORGANIZATIONS:MODIFY"],
                },
            },
            {
                path: "locations/",
                name: RouteNames.locationsView,
                redirect: { name: RouteNames.locationsListView },
                component: () => import("../views/administration/locations/Locations.vue"),
                children: [
                    {
                        path: "list",
                        name: RouteNames.locationsListView,
                        component: () => import("@/views/administration/locations/list/LocationsList.vue"),
                        meta: {
                            permissions: ["LOCATIONS:READ"],
                        },
                    },
                    {
                        path: "edit/:id/:active?",
                        name: RouteNames.locationEditView,
                        props: {
                            id: Number,
                            comp: String,
                        },
                        component: () => import("@/views/administration/locations/edit/LocationEdit.vue"),
                        meta: {
                            permissions: ["LOCATIONS:READ", "LOCATIONS:MODIFY"],
                        },
                    },
                ],
            },
            {
                path: "location-device/edit/:id/:active?",
                name: RouteNames.locationDeviceEdit,
                props: {
                    id: Number,
                },
                component: () => import("@/views/administration/location-devices/edit/LocationDeviceEdit.vue"),
            },
            {
                path: "devices",
                name: RouteNames.devicesView,
                redirect: { name: RouteNames.devicesListView },
                component: () => import("../views/administration/devices/Devices.vue"),
                children: [
                    {
                        path: "list",
                        name: RouteNames.devicesListView,
                        component: () => import("@/views/administration/devices/list/DevicesList.vue"),
                        meta: {
                            permissions: ["DEVICES:READ"],
                        },
                    },
                    {
                        path: "edit/:id",
                        name: RouteNames.deviceEditView,
                        props: {
                            id: String,
                        },
                        component: () => import("@/views/administration/devices/edit/DeviceEdit.vue"),
                        meta: {
                            permissions: ["DEVICES:READ", "DEVICES:MODIFY"],
                        },
                    },
                    {
                        path: "logs/:imei",
                        name: RouteNames.deviceLogsView,
                        props: {
                            imei: String,
                        },
                        component: () => import("@/views/administration/devices/logs/DevicesLogs.vue"),
                        meta: {
                            permissions: ["DEVICES:READ", "DEVICES:MODIFY"],
                        },
                    },
                ],
            },
            {
                path: "departments",
                name: RouteNames.deviceDepartmentsView,
                redirect: { name: RouteNames.deviceDepartmentsListView },
                component: () => import("../views/administration/device-departments/DeviceDepartments.vue"),
                children: [
                    {
                        path: "list",
                        name: RouteNames.deviceDepartmentsListView,
                        component: () => import("@/views/administration/device-departments/list/DeviceDepartmentsList.vue"),
                        meta: {
                            permissions: ["DEPARTMENTS:READ"],
                        },
                    },
                    {
                        path: "edit/:id",
                        name: RouteNames.deviceDepartmentsEditView,
                        props: {
                            id: String,
                        },
                        component: () => import("@/views/administration/device-departments/edit/DeviceDepartmentsEdit.vue"),
                        meta: {
                            permissions: ["DEPARTMENTS:READ", "DEPARTMENTS:MODIFY"],
                        },
                    },
                ],
            },
            {
                path: "teams",
                name: RouteNames.teamsView,
                redirect: { name: RouteNames.teamsListView },
                component: () => import("../views/administration/teams/Teams.vue"),
                children: [
                    {
                        path: "list",
                        name: RouteNames.teamsListView,
                        component: () => import("@/views/administration/teams/list/TeamList.vue"),
                        meta: {
                            permissions: ["TEAMS:READ"],
                        },
                    },
                    {
                        path: "edit/:id",
                        name: RouteNames.teamsEditView,
                        props: {
                            id: String,
                        },
                        component: () => import("@/views/administration/teams/edit/TeamEdit.vue"),
                        meta: {
                            permissions: ["TEAMS:READ", "TEAMS:MODIFY"],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/contact",
        name: RouteNames.contantView,
        component: () => import("../views/Contact.vue"),
    },
    {
        path: "/my-organization/:active?",
        name: RouteNames.myOrganizationView,
        props: {
            id: Number,
        },
        meta: {
            requiresAuth: true,
        },
        component: () => import("../views/MyOrganization.vue"),
    },
    { path: "/:catchAll(.*)", redirect: { name: RouteNames.dashboardView } },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    const isLoggedIn = AuthService.isSignedIn();
    if (to.name == "LoginView" && isLoggedIn) {
        next({ name: RouteNames.dashboardView });
    }
    else {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (!isLoggedIn) {
                next({ name: "LoginView" });
            }
            else {
                if (to.meta.permissions && Array.isArray(to.meta.permissions)) {
                    if (accountModule.hasPermission(to.meta.permissions)) {
                        next();
                    }
                }
                else {
                    next();
                }
            }
        }
        else {
            next();
        }
    }
});
export default router;
