import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";
import "@/styles/quasar.scss";
import "@/styles/quasar.variables.scss";
import Notify from 'quasar/src/plugins/Notify.js';
;
// To be used on app.use(Quasar, { ... })
export default {
    config: {
        brand: {
            "primary-teal": "#1FBBBB",
            "primary-grey": "#3d4644",
            "secondary-purple": "#26A69A",
            "secondary-orange": "#F7925C",
            "secondary-green": "#1B3B31",
            "secondary-white": "#EBEBE0",
            "bg-secondary-white": "#FFFFFF",
            "bg-secondary": "#F6F6F4",
            "bg-white": "white",
            "color-white": "white",
            "color-grey": "grey",
            "color-light-grey": "light-grey",
            "teal-accent": "rgba(31, 187, 187, 0.75)",
            "text-grey": "#3d4644",
        },
        screen: {
            bodyClasses: true,
        },
    },
    plugins: { Notify },
};
