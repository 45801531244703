var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import store from "@/store";
let LocationsModule = class LocationsModule extends VuexModule {
    anyAction = false;
    setAnyAction() {
        this.anyAction = !this.anyAction;
    }
    async addOrUpdateLocation() {
        this.setAnyAction();
    }
    get changeWatcher() {
        return this.anyAction;
    }
};
__decorate([
    Mutation
], LocationsModule.prototype, "setAnyAction", null);
__decorate([
    Action
], LocationsModule.prototype, "addOrUpdateLocation", null);
LocationsModule = __decorate([
    Module
], LocationsModule);
export const locationsModule = new LocationsModule({ store, name: "locations" });
