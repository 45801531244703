import { LocationClient, LocationFilterFields, PagedListOfLocationDTO, } from "@/api/nswag.generated";
export default class LocationService {
    locationsClient = new LocationClient();
    async getLocationsAsync(pageSize, pageNumber, orderBy, descending, searchQuery, filter) {
        if (filter == null) {
            filter = new LocationFilterFields();
        }
        const loc = await this.locationsClient.list(filter, pageSize, pageNumber, orderBy, descending, searchQuery, null);
        if (loc && loc.locations) {
            return loc.locations;
        }
        return new PagedListOfLocationDTO();
    }
    async getOrganizationLocationsAsync(pageSize, pageNumber, orderBy, descending, searchQuery, organizationId, filter) {
        if (filter == null) {
            filter = new LocationFilterFields();
        }
        const loc = await this.locationsClient.listOrganizationLocations(filter, pageSize, pageNumber, orderBy, descending, searchQuery, null, organizationId);
        if (loc && loc.locations) {
            return loc.locations;
        }
        return new PagedListOfLocationDTO();
    }
    async createAsync(command) {
        return await this.locationsClient.create(command);
    }
    async update(command) {
        return this.locationsClient.put(command.id, command);
    }
    async getUserLocations(withInactive = false) {
        return await this.locationsClient.getUserLocations(withInactive);
    }
    async getLocationById(locationId) {
        return await this.locationsClient.getLocationById(locationId);
    }
}
