const routeNames = {
    dashboardView: "LocationDashboardView",
    bestPracticesView: "BestPracticesView",
    loginView: "LoginView",
    accountView: "AccountView",
    forgotPasswordView: "ForgotPasswordView",
    userView: "UsersView",
    userListView: "UserListView",
    userEditView: "UserEditView",
    organizationView: "OrganizationsView",
    myOrganizationView: "MyOrganizationView",
    permissionsView: "PermissionsView",
    organizationDetailsView: "OrganizationDetailsView",
    locationsView: "LocationsView",
    devicesView: "DevicesView",
    deviceDepartmentsView: "DeviceDepartmentsView",
    deviceDepartmentsListView: "DeviceDepartmentsListView",
    deviceDepartmentsEditView: "DeviceDepartmentsEditView",
    teamsView: "TeamsView",
    teamsListView: "TeamsListView",
    teamsEditView: "TeamEditView",
    locationsListView: "LocationsListView",
    locationEditView: "LocationEditView",
    devicesListView: "DevicesListView",
    deviceEditView: "DeviceEditView",
    locationDeviceEdit: "LocationDeviceEditView",
    locationDevicesView: "LocationDevicesView",
    resetPasswordView: "ResetPasswordView",
    contantView: "ContactView",
    deviceLogsView: "DevicesLogs"
};
export default routeNames;
