export default {
    home: {
        dashboard: "Dashboard",
        bestPractices: "'De beste praktijken'",
    },
    account: {
        login: "Aanmelden",
        logout: "Afmelden",
        settings: "Accountinstellingen",
        userName: "Gebruikersnaam",
        password: "Wachtwoord",
        confirmPassword: "Wachtwoord bevestigen",
        forgotPassword: "Wachtwoord vergeten?",
        title: "Profiel aanpassen",
        email: "Email",
        send: "Stuur",
        newPassword: "Nieuw wachtwoord",
        newPasswordSameAsOld: "Nieuw wachtwoord mag niet hetzelfde zijn als uw oude wachtwoord.",
        changePassword: "Wijzig wachtwoord",
        usernameAlreadyExists: "Gebruikersnaam bestaat al",
        emailAlreadyExists: "Email bestaat al",
        report: {
            label: "Rapportagevoorkeuren",
            periodLabel: "Periode",
            dayLabel: "Dag",
            hourLabel: "Uur",
            minuteLabel: "Minuut",
            reportStatus: "Rapportages versturen: ",
            day: {
                Sunday: "Zondag",
                Monday: "Maandag",
                Tuesday: "Dinsdag",
                Wednesday: "Woensdag",
                Thursday: "Donderdag",
                Friday: "Vrijdag",
                Saturday: "Zaterdag",
            },
            period: {
                Weekly: "Wekelijks",
            },
        },
    },
    drawer: {
        myLocations: "Mijn locaties",
        myOrganization: "Mijn organisatie",
        administration: "Administratie",
        users: "Gebruikers",
    },
    other: {
        english: "Engels",
        dutch: "Nederlands",
    },
    input: {
        selectLanguage: "Selecteer taal",
        month: "Maand",
        year: "Jaar",
    },
    administration: {
        administration: "Administratie",
        teams: "Teams",
        users: "Gebruikers",
        organizations: "Organisaties",
        devicedepartments: "Afdelingen",
        locations: "Locaties",
        devices: "Tilliften",
        editorganization: "wijzig organisatie",
        organization: "Organisatie",
        organizationdetails: "Organisatie details",
        userlist: "Lijst met gebruikers",
        useredit: "Gebruikerdetails",
        locationedit: "Wijzig locatie",
        locationslist: "Lijst met locaties",
        emailConfirmed: "Email bevestigd",
        teamslist: "Lijst met teams",
        devicedepartmentslist: "Lijst met afdelingen",
        teamedit: "Wijzig team",
        devicedepartmentsedit: "Wjzig afdeling",
        deviceedit: "Wijzig tillift",
        deviceslist: "Lijst met tilliften",
        locationdeviceedit: "Wijzig tillift",
        permissions: "Toestemmingen",
        deviceslogs: "Liftlogboeken",
    },
    permissions: {
        role: "Rol",
        administrationRead: "Administratie:Lezen",
        administrationUpdate: "Administratie:Updaten",
        locationRead: "Locatie:Lezen",
        locationUpdate: "Locatie:Updaten",
    },
    shared: {
        logType: "Feedback type",
        availableLater: "Deze functie zal later beschikbaar worden",
        passive: "Passief",
        search: "Zoeken",
        name: "Naam",
        firstName: "Voornaam",
        lastName: "Achternaam",
        id: "Id",
        email: "Email",
        roles: "Rollen",
        description: "Omschrijving",
        organizationName: "Naam van organisatie",
        locationName: "Locatienaam",
        hardwareId: "Hardware Id",
        isActive: "Is actief",
        active: "Actief",
        team: "Team",
        allTeams: "Teams",
        deviceDepartment: "Tillift afdeling",
        departmentName: "Afdelingsnaam",
        teamName: "Teamnaam",
        deviceName: "Namm lift",
        save: "Opslaan",
        inactive: "Inactief",
        status: "Status",
        confirm: "Bevestigen",
        noResults: "Geen resultaten gevonden.",
        time: "Tijd",
        value: "Waarschuw",
        alertLog: "Ergo alerts",
        requestSuccess: "Succes",
        requestError: "Er heeft zich een fout voorgedaan.",
        hello: "Hallo,",
        messageSentIfExists: "Wachtwoord reset link is verzonden.",
        backToLogin: "Back to login page",
        wrongNameOrPass: "Verkeerd e-mailadres of wachtwoord",
        incorrectData: "Onjuiste gegevens",
        passwordResetSuccess: "Wachtwoord succesvol gereset",
        lift: "Lift",
        department: "Afdeling",
        noDataSelectedMonth: "Er waren geen alerts in de geselecteerde maand.",
        language: "Taal",
        day: "Dag",
        log: "Log",
    },
    actions: {
        add: "Toevoegen",
        cancel: "Annuleren",
        edit: "Wijzigen",
    },
    location: {
        addNew: "Nieuwe locatie toevoegen",
        name: "Naam",
        description: "Omschrijving",
    },
    user: {
        firstName: "Voornaam",
        lastName: "Achternaam",
        email: "Email",
        phoneNumber: "Telefoonnummer",
        organizationName: "Naam van organisatie",
        userName: "Gebruikersnaam",
        password: "Wachtwoord",
        passwordRepeat: "Repeat password",
        addNew: "Nieuwe gebruiker toevoegen",
        edit: "Gebruiker wijzigen",
    },
    team: {
        name: "Teamnaam",
        description: "Omschrijving",
        addNew: "Nieuwe team toevoegen",
        selectedLocation: "Geselecteerde locatie",
        active: "Actief",
        inactive: "Inactief",
        status: "Status",
        teamLeader: "Teamleider",
    },
    deviceDepartment: {
        name: "Afdelingsnaam",
        description: "Omschrijving",
        addNew: "Nieuwe afdeling toevoegen",
        selectedLocation: "Geselecteerde locatie",
        active: "Actief",
        inactive: "Inactief",
        status: "Status",
    },
    organization: {
        addNew: "Nieuwe organisatie toevoegen",
        name: "Naam van organisatie",
        description: "Omschrijving",
        active: "Actief",
        inactive: "Inactief",
        organizationStatus: "Organisatiestatus",
        phoneNumber: "Telefoonnummer",
    },
    device: {
        addNew: "Nieuwe tillift toevoegen",
        macAddress: "MAC Adres",
        hardwareId: "Hardware ID",
        deviceName: "Naam van tillift",
        liftType: "Lifttype",
        activeLocationName: "Actief in locatie",
        activeOrganizationName: "Actief in organisatie",
        imei: "IMEI",
        locationName: "Locatie",
        teamName: "Team",
        department: "Afdeling",
        mobilityClass: "Mobiliteitsklasse",
        series: "Series",
        modelName: "Modelnaam",
        connectedLifts: "Verbonden liften",
        imeiAlreadyExists: "IMEI bestaat al",
        firmwareVersion: "Version",
        firmwareCommit: "Commit",
    },
    physicalLoad: {
        secondaryTitle: "Ergodashboard",
    },
    healthyTransfer: {
        mainLabel: "Percentage van gezonde transfers",
        transferLabel: "van uw transfers waren gezond",
        notOk: "Helaas zijn er ongezonde tranfers geweest.",
        almostOk: "Probeer te focussen op meer gezonde transfers.",
        justOk: "Je bent goed onderweg om te zorgen dat 100% van de transfers gezond waren!",
        keepUp: "Blijf zo doorgaan! Bijna 100% van de transfers waren gezond!",
        excellent: "Goed gedaan! 100% van de transfers waren gezond!",
        transferPercentage: "Het percentage gezonde transfers",
        calculateText: "Er is berekend hoeveel procent van de transfers uitgevoerd zijn binnen de arbo-richtlijnen.",
        arbo: "Arbo-richtlijn",
        arboLimits: "Arbo-limieten voor duwen en trekken",
        oneHand: "met één hand",
        twoHand: "met twee handen",
        noTransfers: "Er zijn geen transfers geregistreerd in de geselecteerde periode.",
    },
    locationDevice: {
        isActive: "Actief",
        deviceName: "Naam van de tillift",
        location: "Locatie",
        device: "Tillift",
        team: "Team",
        deviceDepartment: "Tillift afdeling",
        mobilityClass: "Mobiliteitsklasse",
    },
    routes: {
        myLocations: "Mijn locaties",
    },
    months: {
        january: "Januari",
        february: "Februari",
        march: "Maart",
        april: "April",
        may: "Mei",
        june: "Juni",
        july: "Juli",
        august: "Augustus",
        september: "September",
        october: "Oktober",
        november: "November",
        december: "December",
        januari: "Januari",
        februari: "Februari",
        maart: "Maart",
        mei: "Mei",
        juni: "Juni",
        juli: "Juli",
        augustus: "Augustus",
        oktober: "Oktober",
        styczeń: "Januari",
        luty: "Februari",
        marzec: "Maart",
        kwiecień: "April",
        maj: "Mei",
        czerwiec: "Juni",
        lipiec: "Juli",
        sierpień: "Augustus",
        wrzesień: "September",
        październik: "Oktober",
        listopad: "November",
        grudzień: "December",
    },
    takeAction: {
        action: "Onderneem actie",
        learn: "Leer meer over preventie van fysieke overbelasting",
        learnHref: "Meer lezen",
        checkOut: "Lees de antwoorden op veelvoorkomende vragen.",
        checkOutHref: "Naar FAQ",
        bookAnalysis: "Boek een afspraak met één van onze ergonomie experts.",
        bookAnalysisHref: "Meer te weten komen?",
        learnHowToImprove: "Wilt u meer weten over hoe u kunt verbeteren?",
        expertsToHelp: "Onze experts helpen u graag! Neem contact met ons op om meer inzicht te krijgen in de preventie van fysieke overbelasting.",
        scheduleMeeting: "Boek een afspraak.",
    },
    contact: {
        helpDiscuss: "Onze experts helpen u graag met het bespreken van uw fysieke belastingsdata!",
        howCanHelp: "Hoe kunnen we u helpen?",
    },
    overloadCalendar: {
        calendar: "Kalendar",
    },
    monthlyOverview: {
        monthlyOverview: "Maandoverzicht",
        nrPatientTransfer: "Aantal patiënt transfers",
        nrForceAlerts15: "Aantal gemeten belastingen boven 15 kg",
        nrForceAlerts20: "Aantal gemeten belastingen boven 20 kg",
    },
    annualOverview: {
        annualOverview: "Jaaroverzicht",
    },
    patientMobility: {
        mainLabel: "Patiënt mobiliteitsklassen",
        valueName: "Klassen",
        progressName: "Gemiddeld percentage gezonde transfers",
    },
    departments: {
        mainLabel: "Afdeling",
        valueName: "Naam",
        progressName: "Gemiddeld percentage gezonde transfers",
    },
    liftType: {
        mainLabel: "Lifttype",
        valueName: "Naam",
        progressName: "Gemiddeld percentage gezonde transfers",
    },
    thisMonth: "Deze maand",
    thisYear: "Dit jaar",
    userDetailsKey: {
        organizationName: "Naam van de organisatie",
        phoneNumber: "Telefoonnummer",
        email: "Emailadres",
        lastName: "Achternaam",
        firstName: "Voornaam",
        userName: "Gebruikersnaam",
        roleName: "Rol",
        locationName: "Naam locatie",
        resendActivationEmail: "Stuur activerings e-mail opnieuw",
    },
    rules: {
        required: "Veld is verplicht",
        email: "E-mail is ongeldig",
        length8: "Wachtwoord moet ten minste 8 tekens lang zijn",
        passwordDiff: "Wachtwoord en herhaal wachtwoord komen niet overeen",
        lengthTo30: "Veld is te lang: max 30",
        lengthTo50: "Veld is te lang: max 50",
        lengthTo100: "Veld is te lang: max 100",
        imei: "Imei zou 16 karakters moeten hebben.",
    },
    alertLog: {
        logType: "Feedback type",
        value: "Alerts en transfers",
    },
};
