import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import { accountModule } from "./store/account";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';
;
import quasarUserOptions from "./quasar-user-options";
import i18n from "@/boot/i18n";
import "flag-icon-css/css/flag-icon.css";
import { IdentityClient } from "@/api/nswag.generated";
import { useI18n } from "vue-i18n";
import Plugin from "@quasar/quasar-ui-qcalendar";
import "@quasar/quasar-ui-qcalendar/dist/index.css";
import permission from "@/directives/permission";
import "@/assets/app.css";
import AuthService from "@/services/authorization-service";
const identityClient = new IdentityClient();
(async () => {
    try {
        await AuthService.getAccountClient().refreshToken().then((response) => {
            if (response.jwtToken) {
                AuthService.setToken(response.jwtToken);
            }
        })
            .catch((e) => {
            AuthService.clean();
            Promise.reject();
            router.push({ name: "LoginView" });
        });
        if (AuthService.isLoggedIn.value == true) {
            await accountModule.fetchClaims();
            await accountModule.fetchUser();
        }
    }
    catch (error) {
        //
    }
    const app = createApp(App, {
        setup() {
            const { t } = useI18n({ useScope: "global" });
            return { t };
        },
    });
    app.use(Quasar, quasarUserOptions);
    app.component("QCalendar", Plugin.QCalendar);
    app.component("QCalendarDay", Plugin.QCalendarDay);
    app.component("QCalendarMonth", Plugin.QCalendarMonth);
    app.use(store);
    app.use(router);
    app.use(i18n);
    app.provide("accountClient", identityClient);
    app.mount("#app");
    app.directive("permission", permission);
})();
export { i18n };
