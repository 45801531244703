import script from "./App.vue?vue&type=script&setup=true&lang=ts"
export * from "./App.vue?vue&type=script&setup=true&lang=ts"

import "./App.vue?vue&type=style&index=0&id=3bffa081&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer,QBreadcrumbs,QIcon,QBreadcrumbsEl,QFooter,QToolbar,QImg,QBtn});
