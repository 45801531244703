import { IdentityClient, RevokeTokenRequest } from "@/api/nswag.generated";
import router from "@/router";
import AuthService from "@/services/authorization-service";
import { ref } from "vue";
export default class AuthorizationService {
    static token = "token";
    static roles = "userRoles";
    static privileges = "userPrivileges";
    static isLoggedIn = ref(false);
    static getToken() {
        this.isLoggedIn.value = localStorage.token !== undefined;
        return localStorage.getItem(AuthService.token) ?? null;
    }
    static setToken(token) {
        this.isLoggedIn.value = token !== undefined;
        localStorage.setItem(AuthService.token, token);
    }
    static setPrivileges(privileges) {
        const presentPrivileges = AuthService.getPrivileges();
        localStorage.setItem(AuthService.privileges, JSON.stringify(privileges));
        return presentPrivileges != null && presentPrivileges.length != privileges.length;
    }
    static checkRoles(roles) {
        const existedItems = AuthService.getRoles();
        let updated = false;
        if (existedItems != null &&
            (existedItems.filter((x) => !roles.includes(x)).length > 0 ||
                roles.filter((x) => !existedItems.includes(x)).length > 0))
            updated = true;
        return updated;
    }
    static setRoles(roles, privilegeMap) {
        localStorage.setItem(AuthService.roles, JSON.stringify(roles));
        const privileges = this.getPrivilegesForRoles(roles, privilegeMap);
        this.setPrivileges(privileges);
    }
    static getPrivileges() {
        return JSON.parse(localStorage.getItem(AuthService.privileges));
    }
    static getRoles() {
        return JSON.parse(localStorage.getItem(AuthService.roles));
    }
    static getPrivilegesForRoles(roles, privilegeMap) {
        let privileges = [];
        roles.forEach((r) => (privileges = privileges.concat(privilegeMap[r])));
        return [...new Set(privileges)];
    }
    static clean() {
        localStorage.removeItem(AuthService.token);
        localStorage.removeItem(AuthService.roles);
        localStorage.removeItem(AuthService.privileges);
    }
    static isSignedIn() {
        return !!AuthService.getToken();
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static getTokenData() {
        const token = AuthService.getToken();
        if (token) {
            return JSON.parse(atob(token.split(".")[1]));
        }
        return null;
    }
    static async logout() {
        const token = this.getToken();
        if (token) {
            const client = this.getAccountClient();
            await client.revokeToken(new RevokeTokenRequest({}));
        }
        AuthService.clean();
        await router.push({ name: "LoginView" });
    }
    static getAccountClient() {
        return new IdentityClient();
    }
}
