import { createI18n } from "vue-i18n";
import messages from "@/i18n";
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "en",
    fallbackLocale: "en",
    formatFallbackMessages: true,
    missingWarn: false,
    messages,
});
export default i18n;
