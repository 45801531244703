export default {
    home: {
        dashboard: "Dashboard",
        bestPractices: "Best practices",
    },
    account: {
        login: "Log in",
        logout: "Log out",
        settings: "Account settings",
        userName: "Username",
        password: "Password",
        confirmPassword: "Confirm password",
        forgotPassword: "Forgot password",
        title: "Edit profile",
        email: "Email",
        send: "Send",
        newPassword: "New password",
        newPasswordSameAsOld: "New password cannot be the same as your old password.",
        changePassword: "Change password",
        usernameAlreadyExists: "Username already exists",
        emailAlreadyExists: "Email already exists",
        report: {
            label: "Report preferences",
            periodLabel: "Period",
            dayLabel: "Day of the week",
            hourLabel: "Hour",
            minuteLabel: "Minute",
            reportStatus: "Send reports: ",
            day: {
                Sunday: "Sunday",
                Monday: "Monday",
                Tuesday: "Tuesday",
                Wednesday: "Wednesday",
                Thursday: "Thursday",
                Friday: "Friday",
                Saturday: "Saturday",
            },
            period: {
                Weekly: "Weekly",
            },
        },
    },
    drawer: {
        myLocations: "My locations",
        myorganization: "My organization",
        administration: "Administration",
        users: "Users",
    },
    other: {
        english: "English",
        dutch: "Dutch",
    },
    input: {
        selectLanguage: "Select language",
        month: "Month",
        year: "Year",
    },
    administration: {
        administration: "Administration",
        teams: "Teams",
        users: "Users",
        organizations: "Organizations",
        devicedepartments: "Departments",
        locations: "Locations",
        devices: "Devices",
        editorganization: "Edit organization",
        organization: "Organization",
        organizationdetails: "Organization details",
        userlist: "Users list",
        useredit: "User details",
        locationedit: "Edit location",
        locationslist: "Locations list",
        emailConfirmed: "Email confirmed",
        teamslist: "Teams list",
        devicedepartmentslist: "Departments list",
        teamedit: "Edit team",
        devicedepartmentsedit: "Edit department",
        deviceedit: "Edit device",
        deviceslist: "Devices list",
        locationdeviceedit: "Edit device",
        permissions: "User permissions",
        deviceslogs: "Device logs",
    },
    permissions: {
        role: "Role",
        administrationRead: "Administration:Read",
        administrationUpdate: "Administration:Update",
        locationRead: "Location:Read",
        locationUpdate: "Location:Update",
    },
    shared: {
        logType: "Feedback type",
        availableLater: "This feature will become available later this year!",
        passive: "Passive",
        search: "Search",
        name: "Name",
        firstName: "First name",
        lastName: "Last name",
        id: "Id",
        email: "Email",
        roles: "Roles",
        description: "Description",
        organizationName: "Organization name",
        locationName: "Location name",
        hardwareId: "Hardware Id",
        isActive: "Is active",
        active: "Active",
        team: "Team",
        allTeams: "All teams",
        deviceDepartment: "Device department",
        departmentName: "Department name",
        teamName: "Team name",
        deviceName: "Device name",
        save: "Save",
        inactive: "Inactive",
        status: "Status",
        confirm: "Confirm",
        noResults: "No results found.",
        time: "Time",
        value: "Alert",
        alertLog: "Ergo alerts",
        requestSuccess: "Success",
        requestError: "An error occured",
        hello: "Hello,",
        messageSentIfExists: "Password reset link has been sent.",
        backToLogin: "Back to login page",
        wrongNameOrPass: "Wrong email or password",
        incorrectData: "Incorrect data",
        passwordResetSuccess: "Successful password reset",
        lift: "Lift",
        department: "Department",
        noDataSelectedMonth: "There were no alerts in selected month.",
        language: "Language",
        day: "Day",
        log: "Log",
    },
    actions: {
        add: "Add",
        cancel: "Cancel",
        edit: "Edit",
    },
    location: {
        addNew: "Add new location",
        name: "Name",
        description: "Description",
    },
    user: {
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phoneNumber: "Phone number",
        organizationName: "Organization name",
        userName: "Username",
        password: "Password",
        passwordRepeat: "Repeat password",
        addNew: "Add new user",
        edit: "Edit user",
    },
    team: {
        name: "Team name",
        description: "Description",
        addNew: "Add new team",
        selectedLocation: "Selected location",
        active: "Active",
        inactive: "Inactive",
        status: "Status",
        teamLeader: "Team leader",
    },
    deviceDepartment: {
        name: "Department name",
        description: "Description",
        addNew: "Add new department",
        selectedLocation: "Selected location",
        active: "Active",
        inactive: "Inactive",
        status: "Status",
    },
    organization: {
        addNew: "Add new organization",
        name: " Organization name",
        description: "Description",
        active: "Active",
        inactive: "Inactive",
        organizationStatus: "Organization status",
        phoneNumber: "Phone number",
    },
    device: {
        addNew: "Add new device",
        macAddress: "MAC address",
        hardwareId: "Hardware ID",
        deviceName: "Device name",
        liftType: "Lift type",
        activeLocationName: "Active in location",
        activeOrganizationName: "Active in organization",
        imei: "IMEI",
        locationName: "Location",
        teamName: "Team",
        department: "Department",
        mobilityClass: "Mobility class",
        series: "Series",
        modelName: "Model name",
        connectedLifts: "Connected lifts",
        imeiAlreadyExists: "IMEI already exists",
        firmwareVersion: "Version",
        firmwareCommit: "Commit",
    },
    physicalLoad: {
        secondaryTitle: "Ergodashboard",
    },
    healthyTransfer: {
        mainLabel: "Healthy transfer percentage",
        transferLabel: "of your transfers were healthy",
        notOk: "Sorry to say but you are not in a way to reach healthy transfers",
        almostOk: "Try to focus on a way to reach better healthy transfers",
        justOk: "You are about being in a good way to reach 100% healthy transfers",
        keepUp: "Keep up the good work! You are on your way to reach your goal of 100% healthy transfers!",
        excellent: "Good job! You reached goal of 100% healthy transfers!",
        transferPercentage: "The healthy transfer percentage",
        calculateText: "We calculate how many % of your transfers are within recommended limits according to arbo regulations.",
        arbo: "Arbo regulation",
        arboLimits: "Arbo limits for pushing & pulling",
        oneHand: "with one hand",
        twoHand: "with two hands",
        noTransfers: "There were no transfers in selected period.",
    },
    locationDevice: {
        isActive: "Active",
        deviceName: "Device name",
        location: "Location",
        device: "Device",
        team: "Team",
        deviceDepartment: "Device department",
        mobilityClass: "Mobility class",
    },
    routes: {
        myLocations: "My locations",
    },
    months: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
        januari: "January",
        februari: "February",
        maart: "March",
        mei: "May",
        juni: "June",
        juli: "July",
        augustus: "August",
        oktober: "October",
        styczeń: "January",
        luty: "February",
        marzec: "March",
        kwiecień: "April",
        maj: "May",
        czerwiec: "June",
        lipiec: "July",
        sierpień: "August",
        wrzesień: "September",
        październik: "October",
        listopad: "November",
        grudzień: "December",
    },
    takeAction: {
        action: "Take action",
        learn: "Learn more about physical overload prevention",
        learnHref: "Read more",
        checkOut: "Check out frequently asked questions",
        checkOutHref: "To FAQ",
        bookAnalysis: "Book an in-person analysis with ergonomic experts",
        bookAnalysisHref: "Learn more",
        learnHowToImprove: "Want to learn how you can improve?",
        expertsToHelp: "Our experts are here to help. Contact us to get more insight in how to prevent physical overload.",
        scheduleMeeting: "Schedule meeting",
    },
    contact: {
        helpDiscuss: "We are here to help You and are happy to discuss your data!",
        howCanHelp: "How can we help?",
    },
    overloadCalendar: {
        calendar: "Calendar",
    },
    monthlyOverview: {
        monthlyOverview: "Monthly overview",
        nrPatientTransfer: "Nr of patient transfers",
        nrForceAlerts15: "Nr of force alerts > 15 kg",
        nrForceAlerts20: "Nr of force alerts > 20 kg",
    },
    annualOverview: {
        annualOverview: "Annual overview",
    },
    patientMobility: {
        mainLabel: "Patient mobility classes",
        valueName: "Classes",
        progressName: "Average healthy transfer percentage",
    },
    departments: {
        mainLabel: "Departments",
        valueName: "Name",
        progressName: "Average healthy transfer percentage",
    },
    liftType: {
        mainLabel: "Lift type",
        valueName: "Name",
        progressName: "Average healthy transfer percentage",
    },
    thisMonth: "This month",
    thisYear: "This year",
    userDetailsKey: {
        organizationName: "Organization name",
        phoneNumber: "Phone number",
        email: "Email address",
        lastName: "Last name",
        firstName: "First name",
        userName: "Username",
        roleName: "Role",
        locationName: "Location name",
        resendActivationEmail: "Resend activation email",
    },
    rules: {
        required: "Field is required",
        email: "Email is not valid",
        length8: "Password must be at least 8 characters length",
        passwordDiff: "Password and repeat password do not match",
        lengthTo30: "Field is too long: max 30",
        lengthTo50: "Field is too long: max 50",
        lengthTo100: "Field is too long: max 100",
        imei: "Imei should have 16 characters.",
    },
    alertLog: {
        logType: "Feedback type",
        value: "Alerts and transfers",
    },
};
