import script from "./MainHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./MainHeader.vue?vue&type=script&setup=true&lang=ts"

import "./MainHeader.vue?vue&type=style&index=0&id=5e0b6120&lang=scss"

const __exports__ = script;

export default __exports__
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QBtn,QToolbarTitle,QImg});
